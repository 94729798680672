import React from 'react';

import PropTypes from 'prop-types';

import {
    Link,
    ListColumns,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';

const BrowseByMakeModal = React.memo(({
    showModal,
    onShowHideModalClick = () => {},
    makes = [],
}) => {

    const handleMakeClick = (event, make) => {
        sendClick('browsAllMakesClick', event, {
            co_txt_url: make,
            makeSelection: true,
            pageType: 'homepage',
        });
    };

    const getListItems = () => makes?.map((make) => {
        const href = `/cars-for-sale/${make.toLowerCase()}`;

        return (
            <Link
                key={`link-${make}`}
                href={href}
                data-value={make}
                rel="nofollow"
                onClick={(event) => handleMakeClick(event, make)}
            >
                {make}
            </Link>
        );
    });

    return (
        <Modal
            dialogClassName="browseByMakeModal"
            show={showModal}
            onHide={onShowHideModalClick}
            bsSize="large"
            eventId="browse-make-modal"
            data-cmp="BrowseByMakeModal"
        >
            <ModalHeader closeButton>
                <ModalTitle className="text-center text-size-600">
                    Browse By Make
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <ListColumns
                    className="list-unstyled"
                    xs={2}
                    sm={4}
                    md={5}
                    lg={6}
                    items={getListItems()}
                />
            </ModalBody>
        </Modal>
    );
});

BrowseByMakeModal.propTypes = {
    makes: PropTypes.array,
    showModal: PropTypes.bool,
    onShowHideModalClick: PropTypes.func,
};

export default BrowseByMakeModal;
