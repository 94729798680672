import React from 'react';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withBonnetPage } from '@bonnet/next/page';

import { pageNames } from '@atc/bonnet-paths';

import {
    addFordLandingPageData,
    ctxPresetFindCar,
    dispatchFordLandingPageData,
    withDataIslandDispatcher,
    withInterestRates,
} from '@/context-middleware';

import {
    withRequestOptions,
} from '@/context-middleware/srp';

import * as flpDucks from '@/ducks/flp';

import {
    CanonicalUrlModule,
    CookieConsentModule,
    PersonalizationEngineModule,
} from '@/modules';

import {
    CommonHeadContainer,
    FordSEOContentContainer,
} from '@/containers';

import FBALandingAnalytics from '@/containers/landing/FBALandingAnalytics';
import FbaPageEventsContainer from '@/containers/landing/FbaPageEventsContainer';
import HeroSearchBadgeContainer from '@/containers/landing/HeroSearchBadgeContainer';

const { FORD_LANDING } = pageNames;

function LandingPage({
    currentUrl,
    query,
}) {

    return (
        <>
            <CommonHeadContainer
                pageName={FORD_LANDING}
                currentUrl={currentUrl}
                query={query}
            />
            <FbaPageEventsContainer />
            <main aria-labelledby="landing-heading">
                <HeroSearchBadgeContainer />
                <div className="ford-landing-content-container">
                    <FordSEOContentContainer />
                </div>
            </main>
            <FBALandingAnalytics />
        </>
    );
}

LandingPage.getInitialProps = async (ctx) => {

    await withCtxMiddleware([
        ctxPresetFindCar({
            pageName: FORD_LANDING,
            preFetchCtxMiddleware: [
                withRequestOptions(),

            ],
            fetcher: addFordLandingPageData,
            dispatcher: dispatchFordLandingPageData,
        }),
        ctx.withModuleCtxMiddleware(),
        withInterestRates(),
        withDataIslandDispatcher(),
    ], ctx);
    return {
        currentUrl: ctx.data.currentUrl,
        query: ctx.query,
    };
};

const { BonnetPageComponent } = withBonnetPage(LandingPage, {
    reducers: [
        ...Object.values(flpDucks),
    ],
    modules: [
        CanonicalUrlModule,
        CookieConsentModule,
        PersonalizationEngineModule,
    ],
});

export default BonnetPageComponent;
