import React from 'react';

import PropTypes from 'prop-types';

import { Modal, ModalBody, ModalHeader, ModalTitle } from 'reaxl';
import { sendClick } from 'reaxl-analytics';

import BrowseByStyle from '@/components/BrowseByStyle';

// get itemCard styles
const getStyles = (styleCodes) => {
    const bodyStyles = [];
    let style;

    Object.keys(styleCodes)
        .forEach((key) => {
            const {
                src,
                link,
                displayName,
            } = styleCodes[key];

            let srpLink = `/cars-for-sale${link}`.toLowerCase();

            // if trailing slash, remove
            if (srpLink.slice(-1) === '/') {
                srpLink = srpLink.substring(0, srpLink.length - 1);
            }

            style = {
                src,
                href: srpLink,
                label: displayName,
                rel: 'nofollow',
                alt: `Browse ${displayName} inventory near you`,
            };

            bodyStyles.push(style);
        });

    return { bodyStyles };
};

function BrowseByStyleModal({
    classNames,
    onShowHideModalClick = () => { },
    showModal = false,
    styleClassNames,
    styleCodes = {},
    ...rest
}) {

    const handleStyleClick = (event, item) => {
        const bodyStyle = item.label || '';
        sendClick('browsByStyleClick', event, {
            co_txt_url: bodyStyle,
            styleSelection: true,
            pageType: 'homepage',
            firePixall: true,
            label: `sty_${bodyStyle.toLowerCase()}`,
        });
    };

    const { bodyStyles } = getStyles(styleCodes);

    return bodyStyles.length && (
        <Modal
            dialogClassName="browseByStylesModal"
            className={classNames}
            show={showModal}
            onHide={onShowHideModalClick}
            bsSize="large"
            eventId="browse-style-modal"
        >
            <ModalHeader closeButton>
                <ModalTitle className="text-center text-size-600">
                    Shop Vehicles by Style
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <BrowseByStyle
                    className={styleClassNames}
                    bodyStyles={bodyStyles}
                    onClick={handleStyleClick}
                    {...rest}
                />
            </ModalBody>
        </Modal>
    );
}

BrowseByStyleModal.propTypes = {
    /**
     * The className to apply to the root node
     */
    classNames: PropTypes.string,
    /**
     * vehicle listing type
     */
    onHandleStyleClick: PropTypes.func,
    /**
     * callback to handle modal show and hide
     */
    onShowHideModalClick: PropTypes.func,
    /**
     * value that triggers modal display
     */
    showModal: PropTypes.bool,
    /**
     * The className to apply to the BrowseByStyle component
     */
    styleClassNames: PropTypes.string,
    /**
     * List of body and/ or vehicle styles
     */
    styleCodes: PropTypes.object,
};

export default BrowseByStyleModal;
