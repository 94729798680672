// TODO: This component should be moved to SearchBadge with uiContext and be cleaned up once the campaign is finished
import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { BodyStyleButton, Button, Glyphicon, Heading, SubHeading } from 'reaxl';
import { sendClick } from 'reaxl-analytics';

function SearchByVehicleStyleBadge({
    className,
    badgeClassName,
    badgeTitle,
    titleClassName,
    badgeSubTitle,
    browseByLinks,
    onFindByBudget = () => { },
    onSearchBySUVs = () => { },
    onSearchByTrucks = () => { },
    zipCode,
    ...rest
}) {
    const wrapperClassNames = clsx('margin-top-4', badgeClassName);
    const headingClassNames = clsx('display-inline', 'padding-right-2', titleClassName);

    const handleSearchAllVehiclesClick = (event) => {
        sendClick('searchAllVehiclesClick', event, {});
    };

    const handleFindByBudgetClick = (event) => {
        sendClick('findByBudgetClick', event, {});

        onFindByBudget();
    };

    const handleSearchBySUVsClick = (event) => {
        sendClick('searchBySUVsClick', event, {});

        onSearchBySUVs();
    };

    const handleSearchByTrucksClick = (event) => {
        sendClick('searchByTrucksClick', event, {});

        onSearchByTrucks();
    };

    return (
        <div
            data-cmp="SearchBadge"
            className={className}
            {...rest}
        >
            <div className={wrapperClassNames}>
                <div className="row">
                    <div className="col-xs-12 col-sm-7 padding-horizontal-2">
                        <Heading
                            className={headingClassNames}
                            data-cmp="txt-hi-header"
                            componentClass="h1"
                        >
                            {badgeTitle}
                        </Heading>
                        {badgeSubTitle && (
                            <SubHeading
                                data-cmp="badgeSubTitle"
                                className="display-inline-block line-height-0"
                            >
                                {badgeSubTitle}
                            </SubHeading>
                        )}
                    </div>
                    {browseByLinks && (
                        <div className="col-xs-12 col-sm-5 text-nowrap margin-bottom-0 margin-top-2 margin-top-sm-4">
                            {browseByLinks}
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 margin-top-2">
                        <Button
                            block
                            bsStyle="primary"
                            data-cmp="SearchAllVehicles"
                            onClick={handleSearchAllVehiclesClick}
                            className="text-overflow"
                            href={`/cars-for-sale/all${zipCode ? `?zip=${zipCode}` : ''}`}
                        >
                            Search All Certified Vehicles
                        </Button>
                    </div>
                    <div className="col-xs-12 col-sm-3 margin-top-2">
                        <Button
                            block
                            bsStyle="secondary"
                            onClick={handleFindByBudgetClick}
                            data-cmp="FindCarsByBudget"
                            className="text-overflow"
                        >
                            <Glyphicon
                                glyph="wallet"
                                className="margin-right-2"
                            />
                            Find Cars By Budget
                        </Button>

                    </div>
                    <div className="col-xs-6 col-sm-3 margin-top-2">
                        <BodyStyleButton
                            block
                            onClick={handleSearchBySUVsClick}
                            data-cmp="SearchSuvs"
                            image={{
                                imageSrc: '/content/static/img/fba/landing/fba_suvs_80x34.png',
                                width: '70px',
                                height: '30px',
                            }}
                        >
                            SUVs
                        </BodyStyleButton>
                    </div>
                    <div className="col-xs-6 col-sm-3 margin-top-2">
                        <BodyStyleButton
                            block
                            data-cmp="SearchTrucks"
                            onClick={handleSearchByTrucksClick}
                            image={{
                                imageSrc: '/content/static/img/fba/landing/fba_trucks_80x34.png',
                                width: '70px',
                                height: '30px',
                            }}
                        >
                            Trucks
                        </BodyStyleButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchByVehicleStyleBadge.propTypes /* remove-proptypes */ = {

    /**
     * The className to be applied for root
     */
    className: PropTypes.string,
    /**
     * The labelClassName to be applied for input labels
     */
    labelClassName: PropTypes.string,
    /**
     * The badgeClassName to be applied to the search badge root
     */
    badgeClassName: PropTypes.string,
    /**
     * The titleClassName to be applied to the search badge title
     */
    titleClassName: PropTypes.string,
    /**
     * Prop for handle Find Cars By Budget button
     */
    onFindByBudget: PropTypes.func,
    /**
     * Prop for handle SUVs button
     */
    onSearchBySUVs: PropTypes.func,
    /**
     * Prop for handle Trucks button
     */
    onSearchByTrucks: PropTypes.func,
    /**
     * The badgeTitle used for h1 text
     */
    badgeTitle: PropTypes.string,
    /**
     * The badgeTitle used for h1 text
     */
    badgeSubTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
    ]),
    /**
     * The browseByLinks node to be right of h1 text
     */
    browseByLinks: PropTypes.node,

    /**
     * componentClass to allow defining of wrapping element
     */
    componentClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
    ]),

    /**
     * The zipCode use for passing zipcode
     */
    zipCode: PropTypes.string,
};

export default SearchByVehicleStyleBadge;
