import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ColoredBackground } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { queryDuck } from '@/ducks';

import { flpContentDuck } from '@/ducks/flp';
import { srpLocationTypeDuck } from '@/ducks/srp';

// component
import CrawlPathLinks from '@/containers/CrawlPathLinks';

function FordLandingCrawlPathFooterContainer() {
    const dispatch = useDispatch();

    const queryData = useSelector(queryDuck.selectors.getDuckState);
    const isNational = useSelector(srpLocationTypeDuck.selectors.getDuckState);

    const {
        // brand configs
        seo_links: [isSeoLinksEnabled] = [],
        brand: [, { channel }],
    } = useFeatures([
        'seo_links',
        'brand',
    ]);

    const [hasFetchedCrawlPath, setHasFetchedCrawlPath] = useState(false);

    useEffect(() => {
        if (!hasFetchedCrawlPath) {
            dispatch(flpContentDuck.creators.fetchCrawlPaths({
                brand: 'ford',
                query: {
                    ...queryData,
                },
                isNational,
                channel,
            }));
            setHasFetchedCrawlPath(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFetchedCrawlPath]);

    return (
        isSeoLinksEnabled && (
            <ColoredBackground uiContext="default">
                <div className="margin-horizontal-5">
                    <CrawlPathLinks />
                </div>
            </ColoredBackground>
        )
    );
}

export default FordLandingCrawlPathFooterContainer;
