import React, { useCallback, useState } from 'react';

import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

import { getMarket } from '@atc/bonnet-reference';

import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { FilterLocationModalTrigger } from 'reaxl-filters';
import { Hero } from 'reaxl-organisms';

import flpMakes from '@/config/page-data/branded/flpMakes.js';

import { paymentsDuck, userDuck } from '@/ducks';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import MyWalletContainer from '@/containers/MyWalletContainer';

import LazyComponent from '@/components/LazyComponent';
import SearchByVehicleStyleBadge from '@/components/SearchByVehicleStyleBadge';

import BrowseByLinks from './BrowseByLinks';

function HeroSearchBadgeContainer() {
    const dispatch = useDispatch();
    const zipCode = useSelector(userDuck.selectors.getZip);
    const [filterErrors, setError] = useState({});

    const navigateToSrp = useSrpNavigation();

    const {
        brand: [, { base_url: baseUrl }],
        my_wallet: [enableMyWallet],
    } = useFeatures([
        'brand',
        'my_wallet',
    ]);

    const imgPath = `${baseUrl}content/static/img/fba/landing/`;

    const displayWalletModal = useSelector(paymentsDuck.selectors.getMyWallet)?.displayModal;

    const handleSRPNavigation = useCallback((styleCode) => () => {
        const filtersValues = {
            zip: zipCode,
        };

        if (styleCode) {
            filtersValues.vehicleStyleCode = [styleCode];
        }

        navigateToSrp({
            filtersValues,
            resetPagination: true,
            isNewSearch: true,
            action: 'replace',
        });
    }, [zipCode, navigateToSrp]);

    const handleFindByBudget = (event) => {
        dispatch(paymentsDuck.creators.updateMyWallet({
            displayModal: !displayWalletModal,
            selectedPanelName: 'budget',
        }));
    };

    const handleZipUpdate = useCallback(async (event, filter) => {
        const { value = '' } = event.target;
        if (value.length === 5) {
            const response = await getMarket(value);

            if (response.success) {
                setError({});

                const { payload } = response;

                dispatch(userDuck.creators.setLocation({
                    zip: payload.zip,
                    city: payload.city,
                    state: payload.state,
                }));

            } else {
                setError({ zip: 'Enter a valid ZIP Code' });
            }
        } else {
            setError({ zip: 'Enter a valid ZIP Code' });
        }
    }, [dispatch]);

    const handleHide = useCallback(() => {
        setError({});
    }, []);

    const handleLocationModalSubmit = useCallback((e, onHide) => {
        const hasError = _get(filterErrors, 'zip', false);

        if (!hasError) {
            sendClick('zipSubmitClick', e, {
                zip: zipCode,
            });

            const cookies = new Cookies();

            cookies.set('ATC_USER_ZIP', zipCode, {
                path: '/',
            });
            onHide(e);
        }
    }, [filterErrors, zipCode]);

    const searchBadgeProps = {
        badgeClassName: 'col-sm-12 col-md-10 col-md-offset-1 margin-top-4 inverted',
        badgeTitle: 'Search Certified Used Vehicles',
        browseByLinks: (
            <BrowseByLinks makes={{ ...flpMakes }} />
        ),
        zipCode,
    };

    const filterOptionsWithZipCodeOnly = {
        zip: {
            protected: true,
            collapsed: true,
            label: 'ZIP Code',
            name: 'zip',
        },
    };

    return (
        <Hero
            uiContext="billboard-search"
            contentPosition="topLeft"
            data-cmp="hero"
            block
            src={`${imgPath}fba_hero_1300x300.webp`}
            xsSrc={`${imgPath}fba_hero_360x475.webp`}
            smSrc={`${imgPath}fba_hero_768x300.webp`}
            mdSrc={`${imgPath}fba_hero_1024x300.webp`}
            lgSrc={`${imgPath}fba_hero_1300x300.webp`}
            alt="Ford F-150® SuperCrew® truck parked in a field"
        >
            <LazyComponent>
                <SearchByVehicleStyleBadge
                    badgeSubTitle={(
                        <FilterLocationModalTrigger
                            filters={filterOptionsWithZipCodeOnly}
                            errors={filterErrors}
                            values={{ zip: zipCode }}
                            onOptionChange={handleZipUpdate}
                            onSubmit={handleLocationModalSubmit}
                            onHide={handleHide}
                        />
                    )}
                    onSearchBySUVs={handleSRPNavigation('SUVCROSS')}
                    onSearchByTrucks={handleSRPNavigation('TRUCKS')}
                    onFindByBudget={handleFindByBudget}
                    {...searchBadgeProps}
                />
            </LazyComponent>

            {enableMyWallet && <LazyComponent><MyWalletContainer /></LazyComponent>}
        </Hero>
    );
}

export default HeroSearchBadgeContainer;
