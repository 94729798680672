import React from 'react';

import PropTypes from 'prop-types';

// Components
import { ImageSelector } from 'reaxl-molecules';

const defaultHandler = () => { };

function BrowseByStyles({
    className,
    bodyStyles = [],
    vehicleStyles = [],
    vehicleStylesAlign = 'center',
    onClick = defaultHandler,
    fixedImageLabelWidth,
    fixedImageLabelHeight,
    imageTitlePlaceholderClassName,
    ...rest
}) {

    const imageSelectorClassName = `text-bold display-flex flex-wrap justify-content-${vehicleStylesAlign}`;

    return (
        !!bodyStyles.length && (
            <div
                className={className}
                {...rest}
            >
                <ImageSelector
                    key="imageSelectorBodyKey"
                    className={imageSelectorClassName}
                    data={bodyStyles}
                    onClick={onClick}
                    fixedImageWidth={fixedImageLabelWidth}
                    fixedImageHeight={fixedImageLabelHeight}
                    imageTitlePlaceholderClassName={imageTitlePlaceholderClassName}
                />
                {!!vehicleStyles.length && [
                    <hr
                        key="tagHrKey"
                        className="row margin-vertical-5"
                    />,
                    <ImageSelector
                        key="imageSelectorVehicleKey"
                        className={imageSelectorClassName}
                        data={vehicleStyles}
                        onClick={onClick}
                        fixedImageWidth={fixedImageLabelWidth}
                        fixedImageHeight={fixedImageLabelHeight}
                        imageTitlePlaceholderClassName={imageTitlePlaceholderClassName}
                    />,
                ]}
            </div>
        )
    );
}

BrowseByStyles.propTypes = {
    /**
     * The className to apply to the root node
     */
    className: PropTypes.string,
    /**
    * The className to apply to the image title place holder
    */
    imageTitlePlaceholderClassName: PropTypes.string,
    /**
     * List of body styles to be displayed
     */
    bodyStyles: PropTypes.array,
    /**
     * List of vehicle styles to be displayed
     */
    vehicleStyles: PropTypes.array,
    /**
     * Function to handle tile click
     */
    onClick: PropTypes.func,
    /**
     * Fixed image width
     */
    fixedImageLabelWidth: PropTypes.number,
    /**
     * Fixed image height
      */
    fixedImageLabelHeight: PropTypes.number,
    /**
     * The alignment of vehicles style
    */
    vehicleStylesAlign: PropTypes.oneOf(['start', 'center', 'end']),
};

export default BrowseByStyles;
