import {
    backToResultsClick,
    browsAllMakesClick,
    browsByBudgetClick,
    browsByStyleClick,
    fbaCompareContentClick,
    fbaMoneyBackGuaranteeClick,
    findByBudgetClick,
    makeDropDownClick,
    modelDropDownClick,
    page,
    searchAllVehiclesClick,
    searchBySUVsClick,
    searchByTrucksClick,
    searchSubmitClick,
    zipOnChange,
    zipSubmitClick,
} from 'reaxl-analytics-handlers';

export default {
    page: (metadata, option) => {
        const { data } = metadata;

        return page({
            ...metadata,
            data: {
                ...data,
                friendlyPageName: 'FBA Landing Page',
                pageType: 'search',
                program: 'fba',
                pageEvents: [],
            },
        }, option);
    },
    makeDropDownClick,
    modelDropDownClick,
    searchSubmitClick,
    fbaMoneyBackGuaranteeClick,
    backToResultsClick,
    browsByStyleClick,
    browsByBudgetClick,
    fbaCompareContentClick,
    zipOnChange,
    browsAllMakesClick,
    searchAllVehiclesClick,
    findByBudgetClick,
    searchBySUVsClick,
    searchByTrucksClick,
    zipSubmitClick,
};
